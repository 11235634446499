import { ReactNode } from 'react';

import { AuthLayout } from '@mwell-healthhub/commons';

export interface AuthWrapperProps {
  children: ReactNode;
}

function AuthWrapper(props: AuthWrapperProps) {
  const { children } = props;
  return (
    <div className="h-full bg-neutral3">
      <AuthLayout>{children}</AuthLayout>
    </div>
  );
}
export default AuthWrapper;
